"use client";
import React, { useState } from "react";
import Image from "next/image";

export default function Reviews() {
  const [ratingLength, setratingLength] = useState(0);
  return (
    <>
      
    </>
  );
}
